import React from 'react'
import { useDispatch } from 'react-redux';

import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';

import { switchAuthPage, Login as LoginAction } from '../../redux/action/auth';
import { Danger } from '../../redux/action/toaster';
import { SEND_OTP } from "../../redux/constants/index";

export default function Login() {

    const dispatch = useDispatch()
    const [values, setValues] = React.useState({
        phoneOrEmail: '',
        password: '',
        showPassword: false,
    });

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangePage = () => {
        var forgetPassword =  true
        dispatch(switchAuthPage(SEND_OTP, forgetPassword))
    }

    const handleSubmitLogin = ()=>{
        var phoneOrEmail = values.phoneOrEmail.trim(), password= values.password.trim()
        if(password.length<8) return dispatch(Danger("Password must be 8 characters long."))
        if(Number.isInteger(parseInt(phoneOrEmail))){
            if(phoneOrEmail.length != 11) return dispatch(Danger("Please enter a valid phone number."))
			phoneOrEmail = `88${phoneOrEmail}`
		}else{
			if(!validateEmail(phoneOrEmail)){
               return dispatch(Danger("please enter a valid email."))
			}
		}
        dispatch(LoginAction({phoneOrEmail, password}))
    }

    const validateEmail = (email) =>{
		return email.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	}
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <TextField
                    style={{ width: '100%' }}
                    required
                    id="outlined-required"
                    label="Email or Phone"
                    onChange={handleChange}
                    name="phoneOrEmail"
                    value={values.phoneOrEmail}
                />
                <FormControl  autoComplete="off" style={{ width: '100%' }} sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        name="password"
                        onChange={handleChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>
                <Typography onClick={handleChangePage} sx={{"&:hover":{cursor:'pointer'}}} className="hover-item" textAlign="end" variant="overline" display="block" gutterBottom>
                    Forget Password ?
                </Typography>
                <FormControl style={{ width: '100%' }} sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <Button onClick={handleSubmitLogin} variant="contained">Login</Button>
                </FormControl>
            </div>
        </Box>
    )
}
