import React, { useEffect, useState } from 'react'
import { BiHash } from "react-icons/bi"
import { TiTickOutline } from 'react-icons/ti';
import { BsFillQuestionCircleFill } from "react-icons/bs"
/* @mui component */
import CssBaseline from '@mui/material/CssBaseline'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

/* @custom component */
import axios from "../../../axios"
import { RenderMathElement } from "../mathjaxPreview/MemoizedMathJaxComponent"
import QuestionLoading from "./QuestionLoading"

function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
}

export default function WrittenExam() {

    const params = new URLSearchParams(window.location.search)
    /* @query params */
    var course_id = params.get('courseId')
    var exam_id = params.get('examId')
    var subscription_id = params.get('subscriptionId')
    var token = params.get('token')

    /* @local state */
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)

    useEffect(() => {
        fetchWrittenExam(course_id, exam_id, subscription_id, token)
    }, [course_id, exam_id, subscription_id, token])

    const fetchWrittenExam = async (courseId, examId, subscriptionId, token) => {
        try {
            let url = `/written-exam/exam-by-id?courseId=${courseId}&examId=${examId}&subscriptionId=${subscriptionId}`
            const response = await axios.get(url, {
                headers: {
                    "x-auth-token": token,
                    'Content-Type': 'application/json',
                }
            });
            setData(response.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const questionHeaderUi = (
        <Box sx={{ p: 1 }}>
            <Typography textAlign={'center'} variant="h6" gutterBottom>
                {data?.title}
            </Typography>
            <Typography textAlign={'center'} variant="subtitle1" gutterBottom>
                <BsFillQuestionCircleFill /> &nbsp; Total Question: {data?.noOfQuestion}
            </Typography>
            <Typography sx={{ color: 'green' }} textAlign={'center'} variant="subtitle1" gutterBottom>
                <TiTickOutline />&nbsp; {data?.marksPerQuestion} Mark/Question
            </Typography>
            <Typography textAlign={'center'} variant="subtitle1" gutterBottom>
                <BiHash />&nbsp; Total Marks: {data?.noOfQuestion * data?.marksPerQuestion}
            </Typography>
            <Typography textAlign={'center'} variant="subtitle1" gutterBottom>
                ⏰&nbsp; Time: {secondsToHms(data?.durationInSec)}
            </Typography>
        </Box>
    )

    const questionUi = (
        <Box justifyContent="center"
            alignItems="center" sx={{ width: '100%' }}>
            {data?.questions?.map((item, index) =>
                <Stack sx={{ p: 2 }} key={index}>
                    <RenderMathElement data={`${index + 1}. ${item?.question}`} />
                    <Divider sx={{ width: '100%', mt: 3 }} />
                </Stack>
            )}
        </Box>
    )

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="md">
                <Paper sx={{ mt: 1 }} variant="outlined">
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        {!loading && !data ? <h4>No exam found</h4> : null}
                        {loading && <QuestionLoading />}
                        {!loading && data && questionHeaderUi}
                        <Divider sx={{ width: '100%' }} />
                        {!loading && data && questionUi}
                    </Stack>
                </Paper>
            </Container>
        </React.Fragment>
    )
}
