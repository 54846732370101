import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { useDispatch } from 'react-redux';
import {Registraction as RegistractionAction } from '../../redux/action/auth';
import { Danger } from '../../redux/action/toaster';
//import { LOGIN } from "../../../redux/constants/index";

export default function Registraction() {
    const dispatch = useDispatch()
    const [values, setValues] = React.useState({
        name: '',
        phone: '',
        email: '',
        password: '',
        confirmPassword:'',
        showPassword: false,
        showConfirmPassword: false,
    });
    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleClickShowConfirmPassword = () => {
        setValues({
            ...values,
            showConfirmPassword: !values.showConfirmPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = () =>{
     if(!values.name) return dispatch(Danger("Please enter your name"))
     if(values.phone.length !== 11) return dispatch(Danger("Please enter a valid phone number"))
     if(values.password.length<8) return dispatch(Danger("Password must be 8 charecters long"))
     if(values.password !== values.confirmPassword) return dispatch(Danger("Password doesn't match"))
     dispatch(RegistractionAction(values))
    }
    const handleClearForm = ()=>{
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <TextField
                    autoComplete="off"
                    style={{ width: '100%' }}
                    required
                    id="outlined-required"
                    label="Full Name"
                    type="text"
                    name="name"
                    onChange={handleChange}
                />

                <TextField
                    defaultValue=""
                    type="text"
                    autoComplete="off"
                    style={{ width: '100%' }}
                    required
                    id="outlined-required"
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                />

                <FormControl style={{ width: '100%' }} sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount"> Phone</InputLabel>
                    <OutlinedInput
                        name="phone"
                        autoComplete="off"
                        id="outlined-adornment-amount"
                        value={values.amount}
                        onChange={handleChange}
                        startAdornment={<InputAdornment position="start"><PhoneInTalkIcon /></InputAdornment>}
                        label="Phone"
                    />
                </FormControl>

                <FormControl style={{ width: '100%' }} sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        name="password"
                        autoComplete="off"
                        id="outlined-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>


                <FormControl style={{ width: '100%' }} sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                    <OutlinedInput
                        name="confirmPassword"
                         autoComplete="off"
                        id="outlined-adornment-password"
                        type={values.showConfirmPassword ? 'text' : 'password'}
                        value={values.confirmPassword}
                        onChange={handleChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.confirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Confirm Password"
                    />
                </FormControl>
                <FormControl style={{ width: '100%' }} sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <Button onClick={handleSubmit} variant="contained">Register</Button>
                </FormControl>

                <FormControl style={{ width: '100%', marginBottom: '20px' }} sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <Button onClick={handleClearForm} color="secondary" variant="contained">Clear</Button>
                </FormControl>
            </div>
        </Box>
    )
}
