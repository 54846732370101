
import React from "react";
import MathJax from 'react-mathjax-preview'
const MemoizedMathJaxComponent = ({ data }) => {
  return <>
    <MathJax
      config={{
        showMathMenu : false,
      }}
      math={data} sanitizeOptions={{ USE_PROFILES: { html: true, mathMl: true, svg: true } }} />
  </>
};
export const RenderMathElement = React.memo(MemoizedMathJaxComponent);
