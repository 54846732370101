import React, { useEffect, useState } from "react";
import { Row, Container,Button } from "react-bootstrap";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import axios from "../../axios";
import Header from "./Header";
import Question from "./Question";
import Footer from "./Footer";
//import "./style.css";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Danger } from "../../redux/action/toaster";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

//import image from  "../../images/SubmitImage.png"

export default function Quiz() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [selectAnswer, setSelectAnswer] = useState([]);
  //hook and function for modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //styling for modal
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 380,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  
  // const [token, setToken] = React.useState(localStorage.getItem("Token"));
  const { token } = useSelector((state) => state.auth);
  const [current_page_number, setCurrentPageNumber] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);
  const Break = 990;

  const params = new URLSearchParams(window.location.search);

  var course_id = params.get("course_id");
  var exam_id = params.get("exam_id");
  var subscription_id = params.get("subscription_id");
  var isFree = params.get("isFree");
  var isCatalogCourse= params.get("isCatalogCourse")
  const pagerender = (page) => {
    setCurrentPageNumber(page);
  };

  useEffect(() => {
    let url = `/my-courses/questions?courseId=${course_id}&subscriptionId=${subscription_id}&examId=${exam_id}&isCatalogCourse=${isCatalogCourse}`;
    if (isFree) {
      url = `/free-courses/questions?courseId=${course_id}&subscriptionId=${subscription_id}&examId=${exam_id}&isCatalogCourse=${isCatalogCourse}`;
    }
    const fetchData = async () => {
      try {
        const response = await axios.get(url, {
          headers: {
            "x-auth-token": token,
            "Content-Type": "application/json",
          },
        });
        setData(response.data);
        setLoading(false);
      } catch (error) {
        dispatch(Danger(`${error.response.data}`));
        setLoading(false);
      }
    };
    fetchData();
  }, [course_id, subscription_id, exam_id]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const onchangeSelectAnswer = (selectAnswer) => {
    setSelectAnswer(selectAnswer);
  };
  const onchangeSelectAnswerMbl = (selectAnswer) => {
    setSelectAnswer(selectAnswer);
  };

  const createExamApi = async () => {
   
    const examCreateUrl = `/mcq-exam-results/create-mcq-exam-result?subscriptionId=${subscription_id}&courseId=${course_id}&examId=${exam_id}&isCatalogCourse=${isCatalogCourse}`;
   
    let getQuestionsAnswer = `/my-courses/questions-answer?courseId=${course_id}&subscriptionId=${subscription_id}&examId=${exam_id}&isCatalogCourse=${isCatalogCourse}`;
    
    if (isFree == true) {
      getQuestionsAnswer = `/free-courses/questions-answer?courseId=${course_id}&subscriptionId=${subscription_id}&examId=${exam_id}&isCatalogCourse=${isCatalogCourse}`;
    }
    const response = await axios.get(getQuestionsAnswer, {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
    });
    var questions = response?.data
    var {
      marksPerQuestion,
      negativeMarksPerQuestion,
      durationInSec,
    } = data;

    var total_correct_answer = 0;
    var total_wrong_answer = 0;
    var correct_index = [];
    var wrong_index = [];
    var total_questions = questions.length;
    if (questions.length > 0) {
      questions.map((item) => {
        selectAnswer?.map((item2) => {
          if (item.serialNo == item2.question + 1) {
            if (item.correctAns == item2.selected) {
              total_correct_answer += 1;
              correct_index.push(item.serialNo);
            } else {
              total_wrong_answer += 1;
              wrong_index.push({
                serial: item.serialNo,
                selected: item2.selected,
              });
            }
          }
        });
      });
      return questions
    }
    var timeCount = localStorage.getItem("Quiz_Completion_Time");
    const { hours, minutes, seconds } = JSON.parse(timeCount);

    const avgTimePerQuestionInSec = Math.abs(
      (hours * 3600 + minutes * 60 + seconds - durationInSec) / questions.length);
    try {
      const data = {
        noOfQuestion: total_questions,
        correct: total_correct_answer,
        wrong: total_wrong_answer,
        notAnswered: Math.abs(
          total_correct_answer + total_wrong_answer - questions.length
        ),
        obtainedMarks:
          marksPerQuestion * total_correct_answer -
          total_wrong_answer * negativeMarksPerQuestion,
        avgTimePerQuestionInSec: avgTimePerQuestionInSec,
      };
      setLoading(true)
      const response = await axios.post(examCreateUrl, data, {
        headers: {
          "x-auth-token": token,
          "Content-Type": "application/json",
        },
      });
      setLoading(false)
    } catch (error) {
      dispatch(Danger(`${error?.response.data}`));
    }
  };

  const onTimeComplete = async() => {
    const questionsAnswer = await createExamApi();
    let questions = []
    for(var index in data.questions){
       let body={...data.questions[index]}
       body.correctAns = questionsAnswer[index].correctAns
       questions.push(body)
    }
    const {
      title,
      noOfQuestion,
      marksPerQuestion,
      negativeMarksPerQuestion,
      durationInSec,
    } = data;
    const result = {
      questions,
      selectAnswer,
      title,
      noOfQuestion,
      marksPerQuestion,
      negativeMarksPerQuestion,
      durationInSec,
      isCatalogCourse
    };
    history.push({
      pathname: `/exam-test/result/${exam_id}`,
      state: { result, course_id, subscription_id, exam_id },
    });
    window.location.reload();
  };

  const handleSubmitQuiz = async () => {
    const questionsAnswer = await createExamApi();
    let questions = []
    for(var index in data.questions){
       let body={...data.questions[index]}
       body.correctAns = questionsAnswer[index].correctAns
       questions.push(body)
    }
    const {
      title,
      noOfQuestion,
      marksPerQuestion,
      negativeMarksPerQuestion,
      durationInSec,
    } = data;
    const result = {
      questions,
      selectAnswer,
      title,
      noOfQuestion,
      marksPerQuestion,
      negativeMarksPerQuestion,
      durationInSec,
      isCatalogCourse
    };
    history.push({
      pathname: `/exam-test/result/${exam_id}`,
      state: { result, course_id, subscription_id, exam_id },
    });
    window.location.reload();
  };

  if (!loading) {
    if (data) {
      if (data.questions.length == 0) {
        return (
          <Container>
            <Row
              className="d-flex justify-content-center"
              style={{
                paddingTop: "100px",
                marginBottom: "20px",
                width: "100%",
              }}
            >
              <h2>No Quiz Found</h2>
            </Row>
          </Container>
        );
      }
    }
  }

  return (
    <div className="container">
      {loading ? (
        <SimpleBackdrop />
      ) : (
        <div>
          <Header
            title={data?.title}
            course={data?.course}
            examType={data?.examType}
            noOfQuestion={data?.noOfQuestion}
            marksPerQuestion={data?.marksPerQuestion}
            negativeMarksPerQuestion={data?.negativeMarksPerQuestion}
            durationInSec={data?.durationInSec}
            onTimeComplete={onTimeComplete}
          />

          <Question
            page={current_page_number - 1}
            question={data?.questions}
            noOfQuestion={data?.noOfQuestion}
            onchangeSelectAnswer={(selectAnswer) =>
              onchangeSelectAnswer(selectAnswer)
            }
          />

          <Footer
            selectAnswer={selectAnswer}
            pagerender={(current_page_number) =>
              pagerender(current_page_number)
            }
            noOfQuestion={data?.questions.length}
            handleSubmitQuiz={() => handleSubmitQuiz()}
          />

          <div
            style={{ backgroundColor: "#0066CC" }}
            className="btn-block btn"
            onClick={handleOpen}
          >
            PROCEED
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Ready to submit?
              </Typography>
              {/* <div>
            <img src={image} style={{width:"100%",height:"auto"}}/>
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Make Sure you gave out your best. If this is the first time you take this quiz, only this result will be counted. You can practice this exam as many times as you want.
          </Typography> */}
              <div className="d-grid gap-2">
                <Button  size="lg" style={{backgroundColor:"#27AE60"}} onClick={handleSubmitQuiz}>
                  Submit
                </Button>
                <Button  size="lg" style={{backgroundColor:"#C0392B"}}  onClick={handleClose}>
                  Go back
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
}
