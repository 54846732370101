import axios from "../../axios";
import {institutions} from "../../helper/institutions/institutions"
import { LOGIN, LOGOUT, REGISTRACTION,VERIFY_INSTITUTION,HIDE_AUTH_MODAL, TOGGLE_AUTH_MODAL, SWITCH_AUTH_PAGES, SEND_OTP, CHANGE_PASSWORD, VERIFY_OTP } from "../constants/index";
import { Danger, Info, Success } from "./toaster";

export const validateInstitution = () => async (dispatch) => {
  try {
     localStorage.removeItem("isVerifiedInstitution");
     dispatch({ type: VERIFY_INSTITUTION, payload: [] })
  } catch (error) {
     //dispatch(Danger(`${error.response.data}`))
  }
};

export const Login = ({ phoneOrEmail, password }) => async (dispatch) => {
  try {
    const response = await axios.post('/auth', {
      phoneOrEmail: phoneOrEmail,
      password: password,
    });
    dispatch(Success(`${response.data}`))
    var userToken = response.headers["x-auth-token"];
    if (userToken) {
      if (userToken.length > 0) {
        localStorage.setItem("Token", userToken);
      }
    }
    if (response.status == 202) {
      dispatch({ type: VERIFY_OTP, payload: phoneOrEmail })
    } else if (response.status === 200) {
      dispatch({ type: LOGIN, payload: {showModal:false , token :userToken} })
      userFetchData(userToken, dispatch)
    }
  } catch (error) {
     dispatch(Danger(`${error.response.data}`))
  }
};


export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT, payload: [] })
};

export const toggleAuthModal = (show) => {
  return {
    type: TOGGLE_AUTH_MODAL, payload: show
  }
};


export const switchAuthPage = (pageName, forgetPassword) => {
  return {
    type: SWITCH_AUTH_PAGES, payload: { pageName, forgetPassword }
  }
};


export const changePassword = ({ password, phoneOrEmail }) => async (dispatch) => {
  try {
    const response = await axios.put('/me/reset-password', {
      phoneOrEmail: `${phoneOrEmail}`,
      password: password
    });
    
    dispatch(Success(`${response.data}`))
    if (response.status == 200) {
      var userToken = response.headers["x-auth-token"];
      localStorage.setItem("Token", userToken)
      dispatch({ type: HIDE_AUTH_MODAL, payload: [] })
      userFetchData(userToken, dispatch);
    }
  } catch (error) {
    dispatch(Danger(`${error.response.data}`))
  }
};



export const sendOTP = (phone) => async (dispatch) => {
  try {
    const response = await axios.post('/auth/forget-password', {
      phoneOrEmail: phone
    });
    if (response.status == 202) {
      dispatch(Success("Check your OTP"))
    }
    dispatch(Info(response.data));
    dispatch({ type: SEND_OTP, payload: phone });
  } catch (error) {
    dispatch(Danger(`${error.response.data}`))
    if (error.status == 400) {
    }
  }
};

export const Registraction = (values) => async (dispatch) => {
  try {
    const response = await axios.post('/auth/register', {
      name: values.name,
      phone: `88${values.phone}`,
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword
    });
    if(response.status == 200){
      dispatch({ type: HIDE_AUTH_MODAL, payload: [] })
    }
    dispatch(Info(response.data));
    dispatch({ type: REGISTRACTION, payload: [] });
  } catch (error) {
    dispatch(Danger(`${error.response.data}`))
    if (error.status == 400) {
     
    }

  }
};


export const verifyOTP = ({ phoneOrEmail, otpCode, forgetPassword }) => async (dispatch) => {
  try {
    const response = await axios.post('/auth/verify-otp',
      {
        phoneOrEmail: phoneOrEmail,
        otpCode: otpCode
      });
    if (forgetPassword) {
      return dispatch({ type: CHANGE_PASSWORD });
    }
    dispatch(Success(`${response.data}`))
    if (response.status == 200) {
      var userToken = response.headers["x-auth-token"];
      localStorage.setItem("Token", userToken)
      dispatch({ type: '', payload: [] })
      userFetchData(userToken, dispatch);
    }
    dispatch({ type: VERIFY_OTP, payload: [] });
  } catch (error) {
    dispatch(Danger(`${error.response.data}`))
  }
};

export const getdata = () => async (dispatch) => {
  try {
    const response = await axios.get('/me');
    dispatch({ type: 'FETCH', payload: [] });
  } catch (error) {
  }
};

async function userFetchData(token, dispatch) {
  const response = await axios.get(`/me`, {
    headers: {
      "x-auth-token": token,
    }
  })
  
  const { goal, role, avatar, name , institution, email, _id} = response.data;
  var userProfile = {goal, role, avatar, name, email, _id , institution};
  localStorage.setItem('userProfile', JSON.stringify(userProfile));
    if (goal.length > 0) {
      try{
        let selectedGoalObject = window.localStorage.getItem("SelectedGoal");
        selectedGoalObject = JSON.parse(selectedGoalObject);
        if(!selectedGoalObject && goal[0]){
          window.localStorage.setItem("SelectedGoal", JSON.stringify(goal[0]));
        }
      }catch(err){
      }
      
      var isValidInstitute = await fetchValidInstitution(institution, token)
      const institutionData = {institution, isValidInstitute};
      localStorage.setItem('isVerifiedInstitution', JSON.stringify(institutionData));
      dispatch({ type: VERIFY_INSTITUTION, payload: [] });
      window.location.href = "/home";
    } else {
      window.location.href = "/setGoal";
    }
}


const fetchValidInstitution = async (institution, token) => {
  const isValidInstitute = institutions && institutions.filter(item => item.title.toLowerCase() == institution?.toLowerCase())
  if (isValidInstitute.length > 0) {
    return true
  } else {
    return false
  }
}
  