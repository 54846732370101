import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import OtpInput from "react-otp-input";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';

import { switchAuthPage } from '../../redux/action/auth';
import { CHANGE_PASSWORD } from "../../redux/constants/index";
import {verifyOTP as VerifyOtpAction} from "../../redux/action/auth";
import { Danger, Success } from '../../redux/action/toaster';
export default function VerifyOTP() {

    const {phoneOrEmail, forgetPassword}  = useSelector(state => state.auth)
    const [otpCode, setOtp] = React.useState("");
    const dispatch = useDispatch()
    const handleChangePage = () => {
        dispatch(switchAuthPage(CHANGE_PASSWORD))
    }
    const handleSubmitOTP = () =>{
        if(otpCode.length<4) return dispatch(Danger("Please enter a valid otp code."))
        dispatch(VerifyOtpAction({phoneOrEmail, otpCode, forgetPassword}))
    }

    const [resendOTP, setResendOTP]=React.useState(false);
	const [showSec, setShowSec] = React.useState(120);
    useEffect(() => setShowSec(showSec), [showSec]);
	
    useEffect(() => {
		const timeId = setTimeout(() => {
            setResendOTP(true);
		}, 120000)
		return () => {
		  clearTimeout(timeId)
		}
	  }, [resendOTP]);

	  useEffect(() => {
		const timer =
		  showSec > 0 &&
		  setTimeout(() => setShowSec(showSec - 1), 1000);
		return () => clearInterval(timer);

	  }, [resendOTP,showSec]);

	function handleClickResendOTP (){
		sendOTP()
		setResendOTP(false);
	}

    function sendOTP(){
		const sendOTP = `${process.env.REACT_APP_API_URL}/auth/forget-password`;
		fetch(sendOTP, {
			method: "POST",
			body: JSON.stringify({
				phoneOrEmail: /^\d+$/.test(phoneOrEmail) ? `${phoneOrEmail}` : phoneOrEmail
			}),
			headers: {
				"Content-Type": "application/json",
				Connection: "keep-alive",
				"Content-Length": "<calculated when request is sent>",
				Host: "<calculated when request is sent>",
				"Accept-Encoding": "gzip, deflate, br",
				Accept: "*/*",
			},
		})
			.then(async (resp) => {
				if(resp.status == 202) {
					dispatch(Success("Check your OTP"))
				}else {
				}
			})
			.catch(function (error) {
				
			});
	}


    return (
        <>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <FormControl style={{ width: '100%' }} sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <Typography sx={{ m: 1 }} className="hover-item" textAlign="center" variant="h5" display="block" gutterBottom>
                            OTP Verification
                        </Typography>

                    </FormControl>
                    <FormControl style={{ width: '100%' }} sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <OtpInput
                            value={otpCode}
                            onChange={(otp) => {
                                setOtp(otp)
                            }}
                            isInputNum={true}
                            separator={
                                <span>
                                    <strong>.</strong>
                                </span>
                            }
                            inputStyle={{
                                width: "3rem",
                                height: "3rem",
                                margin: "0 1rem",
                                fontSize: "2rem",
                                borderRadius: 4,
                                border: "1px solid rgba(0,0,0,0.3)",
                                color: "black",
                            }}
                        />

                    </FormControl>
                    <FormControl style={{ width: '100%' }} sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <Typography sx={{ m: 1 }} className="hover-item" textAlign="center" variant="overline" display="block" gutterBottom>
                            Time Remaining : &nbsp; &nbsp;{showSec} Seconds
                        </Typography>

                    </FormControl>
                    <FormControl style={{ width: '100%' }} sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <Button onClick={handleSubmitOTP} variant="contained">Verify</Button>
                    </FormControl>
                    {
						resendOTP ?
                        <FormControl style={{ width: '100%' }} sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <Button color="secondary" onClick={()=>{
							setShowSec(120)
							handleClickResendOTP()
						}} variant="contained">Resend</Button>
                        </FormControl>
					 : null
					}
                   
                </div>
            </Box> </>
    )
}
