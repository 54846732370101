const GET_SUBJECTS = 'GET_SUBJECTS';
const GET_SUBJECTS_OF_FREE_COURSE = 'GET_SUBJECTS_OF_FREE_COURSE';
const LOADING = 'LOADING';

const INITIAL_STATE = {
    subjects: [],
    loading: true,
    courseID: '',
    subscriptionID: '',
    courseName: '',
    isFree : false
};
const subject = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_SUBJECTS:
            return {
                ...state,
                subjects: action.payload.subjects,
                loading: false,
                courseID: action.payload.courseID,
                subscriptionID: action.payload.subscriptionID,
                courseName: action.payload.courseName
            };

        case GET_SUBJECTS_OF_FREE_COURSE:
            return {
                ...state,
                subjects: action.payload.subjects,
                loading: false,
                courseID: action.payload.courseID,
                subscriptionID: action.payload.subscriptionID,
                courseName: action.payload.courseName,
                isFree : true
            };

        case LOADING:
            return {
                ...state, loading: true
            };
        default: return { ...state, loading: false };
    }
};
export default subject;